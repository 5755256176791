import React from 'react';
import './gallery.scss';
import { Link } from "react-router-dom";
import ig  from '../../assets/square-instagram.svg';
import youtube  from '../../assets/youtube.svg';
function Gallery() {
    return (
        <div className='gallery'>
            <div className="title">
                <h1>Gallery</h1>
            </div>
            <div className='gallery-grid'>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/0fbfa67b-c713-4964-8162-9c9aae04dee8?autoplay=false&loop=true&muted=true&preload=true" loading="lazy" title="Colorado" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/b3fdfdd5-e859-4740-b85e-47b58813cc6b?autoplay=false&loop=true&muted=true&preload=true" loading="lazy" title="Vermont"  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/e49ccb40-061c-4c31-885b-62da80470eee?autoplay=false&loop=true&muted=true&preload=true" loading="lazy" title="Yale" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/7cd8df7a-3994-482c-afb3-0c1761e29762?autoplay=false&loop=true&muted=true&preload=true&responsive=true" loading="lazy" title="Hartford" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/9eff17b6-1baf-474c-8508-ecf210080bb4?autoplay=false&loop=true&muted=true&preload=true&responsive=true" loading="lazy"  title="Florida" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/d5a0f807-2251-413b-b025-04013c276d76?autoplay=false&loop=true&muted=true&preload=true&responsive=true" loading="lazy" title="New Hampshire" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/191d57a9-073f-4a55-ac8d-dacf868fd960?autoplay=false&loop=true&muted=true&preload=true&responsive=true" loading="lazy" title="Orbit" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                <div className='gallery-item'>
                    <iframe src="https://iframe.mediadelivery.net/embed/144224/b9ba3317-fcc7-413f-bddf-d93a56474516?autoplay=false&loop=true&muted=true&preload=true&responsive=true" loading="lazy" title="Garden" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                </div>
                {/* Add more items as needed */}
            </div>
           
            <hr/>
            <div className='outro'>
                <h1>Discover how our expertise in drone photography and videography can elevate your visual storytelling. Contact today to discuss your project and let us take your vision to new heights.</h1>
                <button><Link to='/contact'>Contact</Link></button>
                <p>Follow us on social media</p>
                <div class="social">
                    <a href="https://www.instagram.com/horizon_optical_drones/" target="_blank" rel="noreferrer"><img className='ig' src={ig} alt='instagram' /></a>
                    
                    <a href="https://www.youtube.com/@horizonopticaldrones" target="_blank" rel="noreferrer"><img className='youtube' src={youtube} alt='youtube' /></a>
                </div>
            </div>
         
        </div>
    )
}

export default Gallery;