import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/home/home';
import Contact from './components/contact/contact';
import Services from './components/services/services';
import Gallery from './components/gallery/gallery';
import Nav from './components/navbar/nav';
import Eco from './components/contribution/eco';
import Lost from './Lost';
function App() {
  return (
    <div className="App">
      <Router>
      <Nav className='nav'/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contribution" element={<Eco />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path ="*" element={<Lost />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
