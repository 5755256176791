import React, { useRef, useState  } from 'react';
import './contact.scss';
import emailjs from '@emailjs/browser';
import ig  from '../../assets/square-instagram.svg';
import youtube  from '../../assets/youtube.svg';
function Contact() {
        const form = useRef();
        const [submitted, setSubmitted] = useState(false);
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs.sendForm('service_wspi93j', 'template_1vera2i', form.current, 'tOE00XtYzzQbDqgtJ')
            .then((result) => {
                console.log(result.text);
                setSubmitted(true);
            }, (error) => {
                console.log(error.text);
            });
        };
    return (
        <div className='contact'>
            <div className ="iframe"><iframe src="https://iframe.mediadelivery.net/embed/144224/10d3178b-fe2b-411f-aacb-abe82eeb455f?autoplay=true&loop=true&muted=true&preload=true" title='Clouds' loading="lazy"  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true">
                </iframe>
            </div>
            <div className='content'>
                <h1>Contact</h1>
                <div className='survey'>
                    {submitted ? (
                    <div className='thanks'>
                        <h1>Thank you for your message! </h1>
                        <h1>We will get back to you as soon as posible!</h1>
                        <p>Follow us on social media</p>
                        <div class="social">
                            <a href="https://www.instagram.com/horizon_optical_drones/" target="_blank" rel="noreferrer"><img className='ig' src={ig} alt='instagram' /></a>
                            
                            <a href="https://www.youtube.com/@horizonopticaldrones" target="_blank" rel="noreferrer"><img className='youtube' src={youtube} alt='youtube' /></a>
                        </div>
                    </div>
                    ) : (
                        <div className='form'>
                            <form ref={form} onSubmit={sendEmail}>
                                <label>Name</label>
                                <input type="text" name="name" />
                                <label>Phone</label>
                                <input type="text" name="phone" />
                                <label>Email</label>
                                <input type="email" name="email" />
                                <label>Message</label>
                                <textarea name="message" />
                                <button type="submit" value="Send" > Submit</button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Contact;