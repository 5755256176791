import { React } from 'react';
import logo  from '../../assets/logo.png';
import { Link } from "react-router-dom";

import './nav.scss';
//navbar function

function Nav() {
    const handleClick = () => {
        document.getElementById("menu-btn").checked = false;
    }
    return (
        <div className="header">
           <div className='logo'>
                <Link to='/'><img  src={logo} alt='logo' /></Link> 
            </div>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            <ul className="menu">
                <li><Link to='/' onClick={handleClick}>Home</Link></li>
                <li><Link to='/services' onClick={handleClick}>Services</Link></li>
                <li><Link to='/contribution' onClick={handleClick}>Contribution</Link></li>
                <li><Link to='/gallery' onClick={handleClick}>Gallery</Link></li>
                <li><Link to='/contact' onClick={handleClick}>Contact</Link></li>
            </ul>
        {/* </div> */}
        </div>

    )
}

export default Nav;